var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("ดูรายการออเดอร์")])]),_c('v-tabs',_vm._l((_vm.tabitems),function(item){return _c('v-tab',{key:item.key,on:{"click":function($event){return _vm.tab(item)}}},[_vm._v(_vm._s(item.text))])}),1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"mr-4",attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{staticClass:"mb-4",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":"ค้นหา"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"mt-4 text-right",attrs:{"cols":"12","md":"6"}},[_c('ExportOrder',{attrs:{"data":_vm.itemsExport}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersOrder,"items":_vm.items,"search":_vm.search,"items-per-page":10},scopedSlots:_vm._u([{key:"item.orderdate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.orderdate).toLocaleDateString())+" ")]}},{key:"item.paymentPrice",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.convertPrice(item.paymentPrice)))])]}},{key:"item.orderStatus",fn:function(ref){
var item = ref.item;
return [(item.orderStatus == 'WAITING_PAYMENT')?_c('v-chip',{attrs:{"color":"blue","dark":""}},[_vm._v("รอชำระเงิน")]):_vm._e(),(item.orderStatus == 'SUCCESS')?_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v("ชำระเงินสำเร็จ")]):_vm._e(),(item.orderStatus == 'CANCEL')?_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v("ยกเลิก")]):_vm._e(),(item.orderStatus == 'REJECT')?_c('v-chip',{attrs:{"color":"orange","dark":""}},[_vm._v("ตีกลับ")]):_vm._e(),(item.orderStatus == 'DERIVERED')?_c('v-chip',[_vm._v("ส่งแล้ว")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-icon',{staticClass:"mx-2",on:{"click":function($event){return _vm.UpdateOrder(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{on:{"click":function($event){return _vm.DeleteOrder(item)}}},[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }